<script setup lang="ts">
defineEmits<{
  'show-input': [boolean]
}>()
withDefaults(
  defineProps<{
    buttonText?: string
    nocenter?: boolean
    noIcon?: boolean
    notes?: string
    section: string
  }>(),
  {
    buttonText: '',
    nocenter: false,
    noIcon: false,
    notes: '',
  },
)

const { isDesktop } = useBreakpoint()

const buttonColor = (
  showInput: boolean,
  validNumber: boolean,
  showValidation: boolean,
) => {
  if (showInput && !validNumber) {
    return 'grey'
  } else if (!showValidation && showInput && validNumber) {
    return 'primary'
  }

  return 'black'
}
</script>

<template>
  <base-sliding-input
    :hidden-success-message="false"
    :inline="isDesktop"
    :nocenter="nocenter"
    :notes="notes"
    :show-success-message="!isDesktop"
    :section="section"
    class="mt-4 base-sliding--no-border md:mt-0"
    @show-input="(value) => $emit('show-input', value)"
  >
    <template #button="{ showInput, validNumber, showValidation }">
      <base-button
        :color="buttonColor(showInput, validNumber, showValidation)"
        type="submit"
        has-icon
        variant="link"
      >
        <BaseIcon v-if="!noIcon" name="phone" />
        <span class="ml-3 md:ml-6">
          {{ buttonText }}
        </span>
      </base-button>
    </template>
  </base-sliding-input>
</template>

<style>
.base-sliding--no-border .base-vue-tel-input > .w-full {
  @apply z-sticky;
}
.base-sliding--no-border .base-vue-tel-input .vue-tel-input {
  @apply border-0 border-b border-gray-300 shadow-none;
}
.base-sliding--no-border .base-vue-tel-input .vue-tel-input.is-invalid {
  @apply border-error;
}
.base-sliding--no-border .base-vue-tel-input .vue-tel-input.is-clicked {
  @apply shadow-none;
}
.base-sliding_button {
  height: 51px;
  @apply z-10;
}
</style>
